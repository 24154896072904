<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form">
        <b-row class="w-100 align-items-end">
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :clearable="false"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  :is-keyup="true"
                  transfer
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Место хранения отправитель"
              label-for="storage"
            >
              <template v-if="fixed">
                {{ filter_params.storage.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.storage.id"
                  filterable
                  transfer
                  @on-change="changeStorage"
                >
                  <i-option
                    v-for="item in storages_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Место хранения получатель"
              label-for="storage"
            >
              <template v-if="fixed">
                {{ filter_params.targetStorage.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.targetStorage.id"
                  filterable
                  transfer
                  @on-change="changeTargetStorage"
                >
                  <i-option
                    v-for="item in storages_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                lg="6"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    @input="setComment"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  import { ManufactureHeadModel } from '@/models/manufacture-head.model'

  export default {
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: ManufactureHeadModel,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      }
    },

    apollo: {
      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storages_list = data?.Storages ?? []
          if (!this.filter_params.storage.id) this.filter_params.setStorage(data.Storages?.[0])
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: function () {
      return {
        operation_list: [
          {
            name: 'Безналичная продажа',
            id: 'cashless_sale'
          },
          {
            name: 'Наличная продажа',
            id: 'cash_sale'
          }
        ],
        supplier_list: [],
        storages_list: [],
        isUpdatedFilterParams: false
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      changeStorage(id) {
        this.storages_list.filter((item) => {
          if (item.id.includes(id)) this.filter_params.setStorage(item)
        })

        this.$nextTick(() => this.$emit('change'))
      },
      changeTargetStorage(id) {
        this.storages_list.filter((item) => {
          if (item.id.includes(id)) this.filter_params.setTargetStorage(item)
        })

        this.$nextTick(() => this.$emit('change'))
      },
      setOperation(val) {
        this.filter_params.setOperation(val)
        this.$nextTick(() => this.$emit('change'))
      },
      setSupplier(val) {
        const supplier = this.supplier_list.find((el) => el.id === val)
        this.filter_params.setSupplier(supplier)
        this.$emit('change')
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.filter_params.setDate(this.toNormalDate(val))
        this.$emit('change')
      },
      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .params-body {
    padding-top: 0;
    margin-bottom: 16px;
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-3 {
        margin-bottom: 16px;
      }
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }

    #collapse-extraparams {
      textarea {
        overflow: hidden;
        resize: none;
      }
    }
  }
</style>
