var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "equipment-page"
  }, [_c('compound-modal', {
    attrs: {
      "compound": _vm.currentCompound,
      "disabled": ""
    }
  }), _c('equipment-head', {
    attrs: {
      "document": _vm.document,
      "loading": _vm.loading
    },
    on: {
      "onChange": _vm.onChange,
      "makeUsed": _vm.update_status
    }
  }), _c('resizable-table', {
    ref: "equipment-table",
    attrs: {
      "default_fields": _vm.default_fields,
      "items": _vm.document.specifications,
      "inside_card": false,
      "table_name": "manufacture",
      "show_product_search": ""
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.isAllSelected
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item.id);
            }
          }
        })];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        var _data$value$current_c;

        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "text-left",
          staticStyle: {
            "width": "85%"
          }
        }, [_vm._v(" " + _vm._s(data.value.name) + " "), _c('div', {
          staticClass: "b-name article text-left"
        }, [_vm._v(" " + _vm._s(data.value.articul || 'Не указан') + " ")])]), _c('div', {
          staticClass: "calc-icon",
          staticStyle: {
            "width": "28px",
            "height": "28px",
            "padding": "5px"
          },
          on: {
            "click": function ($event) {
              return _vm.openCompound(data.value);
            }
          }
        }, [_c('calc-svg', {
          staticStyle: {
            "height": "18px",
            "width": "18px"
          },
          attrs: {
            "fill": (_data$value$current_c = data.value.current_compound) !== null && _data$value$current_c !== void 0 && _data$value$current_c.id ? '#888888' : 'red'
          }
        })], 1)])];
      }
    }, {
      key: "body_delta",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'delta',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'delta'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'sum',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'sum'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setSum(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_markup",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'markup',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'markup'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setMarkup(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_price",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'price',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'price'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_retailPrice",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'retailPrice',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'retailPrice'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setRetailPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_currentPrice",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.product.retailPrice || '–') + " ")];
      }
    }, {
      key: "body_measurement",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.product.measurement.name || '–') + " ")];
      }
    }, {
      key: "bottom",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "productSearch",
      fn: function () {
        return [!_vm.document.fixed ? _c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "200px"
          }
        }, [_c('product-search', {
          attrs: {
            "document_type": "equipment",
            "document_head": _vm.document.id,
            "document": _vm.document
          },
          on: {
            "select_product": _vm.select_product
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "documents",
      "doc_type": "manufacture",
      "print": false,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "remove_items": function ($event) {
        return _vm.remove_items(_vm.selected);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }