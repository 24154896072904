<template>
  <div class="equipment-page">
    <compound-modal
      :compound="currentCompound"
      disabled
    />
    <!-- <breadcrumbs :extra="document" /> -->
    <equipment-head
      :document="document"
      :loading="loading"
      @onChange="onChange"
      @makeUsed="update_status"
    />
    <resizable-table
      ref="equipment-table"
      :default_fields="default_fields"
      :items="document.specifications"
      :inside_card="false"
      table_name="manufacture"
      show_product_search
    >
      <template #head_id>
        <e-checkbox
          :checked="isAllSelected"
          @click="changeSelectAll"
        />
      </template>
      <template #body_id="{ item }">
        <b-form-checkbox
          :checked="selected?.some((el) => el === item?.id)"
          position="center"
          @change="(val) => addSelected(val, item.id)"
        />
      </template>
      <template #body_product="data">
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="text-left"
            style="width: 85%"
          >
            {{ data.value.name }}
            <div class="b-name article text-left">
              {{ data.value.articul || 'Не указан' }}
            </div>
          </div>
          <div
            style="width: 28px; height: 28px; padding: 5px"
            class="calc-icon"
            @click="openCompound(data.value)"
          >
            <calc-svg
              style="height: 18px; width: 18px"
              :fill="data.value.current_compound?.id ? '#888888' : 'red'"
            />
          </div>
        </div>
      </template>
      <template #body_delta="data">
        <table-row-input
          :ref="data.item.id + 'delta'"
          :value="data.value"
          :fixed="document.fixed"
          :input_id="data.item.id + 'delta'"
          @handle-input="(val) => setDelta(val, data.item)"
          @on-focus="centeredScroll"
        />
      </template>
      <template #body_sum="data">
        <table-row-input
          :ref="data.item.id + 'sum'"
          :value="data.value"
          :fixed="document.fixed"
          :input_id="data.item.id + 'sum'"
          @handle-input="(val) => setSum(val, data.item)"
          @on-focus="centeredScroll"
        />
      </template>
      <template #body_markup="data">
        <table-row-input
          :ref="data.item.id + 'markup'"
          :value="data.value"
          :fixed="document.fixed"
          :input_id="data.item.id + 'markup'"
          @handle-input="(val) => setMarkup(val, data.item)"
          @on-focus="centeredScroll"
        />
      </template>
      <template #body_price="data">
        <table-row-input
          :ref="data.item.id + 'price'"
          :value="data.value"
          :fixed="document.fixed"
          :input_id="data.item.id + 'price'"
          @handle-input="(val) => setPrice(val, data.item)"
          @on-focus="centeredScroll"
        />
      </template>
      <template #body_retailPrice="data">
        <table-row-input
          :ref="data.item.id + 'retailPrice'"
          :value="data.value"
          :fixed="document.fixed"
          :input_id="data.item.id + 'retailPrice'"
          @handle-input="(val) => setRetailPrice(val, data.item)"
          @on-focus="centeredScroll"
        />
      </template>
      <template #body_currentPrice="data">
        {{ data.item.product.retailPrice || '–' }}
      </template>
      <template #body_measurement="data">
        {{ data.item.product.measurement.name || '–' }}
      </template>
      <template #bottom></template>
      <template #productSearch>
        <div
          v-if="!document.fixed"
          class="d-flex"
          style="width: 200px"
        >
          <product-search
            document_type="equipment"
            :document_head="document.id"
            :document="document"
            @select_product="select_product"
          />
        </div>
      </template>
      <!-- <product-search-all
          style="width: 250px"
          type="repackaging"
          class="ml-2"
          @select_product="select_product"
        /> -->
    </resizable-table>
    <table-navbar
      :items="selected"
      items_name="documents"
      doc_type="manufacture"
      :print="false"
      :clear_selected="clearSelected"
      @remove_items="remove_items(selected)"
    />
  </div>
</template>
<script>
  import EquipmentHead from '@/views/manufacture/components/equipment/EquipmentHead'
  import { ManufactureHeadModel } from '@/models/manufacture-head.model'
  import ResizableTable from '@/components/ResizableTable'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { ProductModel } from '@/models/product.model'
  import { ManufactureSpecificationModel } from '@/models/manufacture-spec.model'
  import { CompoundModel } from '@/models/compound.model'
  import CompoundModal from '@/views/manufacture/components/CompoundModal'
  import CalcSvg from '@/views/manufacture/components/icon/CalcSvg'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch.vue'
  import { mapActions } from 'vuex'

  export default {
    name: 'Equipment',
    components: {
      EquipmentHead,
      ResizableTable,
      TableRowInput,
      CompoundModal,
      CalcSvg,
      TableNavbar,
      ProductSearch
    },
    apollo: {
      ManufactureHead: {
        query: require('../../gql/ManufactureHead.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
          }
        },
        result({ data }) {
          this.document = new ManufactureHeadModel(data.ManufactureHead)
          this.setBreadcrumbs({ ...data.ManufactureHead, is_go_back: true })
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        }
      }
    },
    data() {
      return {
        loading: false,
        currentCompound: new CompoundModel(),
        title: this.$route.meta.title,
        document: new ManufactureHeadModel(),
        default_fields: [
          {
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 54
          },
          {
            key: 'product',
            label: 'Наименование товара',
            thStyle: 'width: 250px ;min-width: 250px;',
            checked: true,
            width: 250
          },
          {
            key: 'delta',
            label: 'Общее количество',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 120
          },
          {
            key: 'measurement',
            label: 'Единица измерения',
            thStyle: 'min-width: 54px;width: 54px',
            checked: true,
            width: 120,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'markup',
            label: 'Наценка',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 120
          },
          {
            key: 'retailPrice',
            label: 'Розница новая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 140,
            formatter: (value, key, item) => item.product?.retailPrice ?? 0
          },
          {
            key: 'remainders',
            label: 'Остаток',
            formatter: (value, key, item) => this.getRemainder(item.product.remainders),
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          }
        ],
        isAllSelected: false,
        selected: []
      }
    },

    watch: {
      'document.specifications'(newItems) {
        const currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.document.specifications.length === 0) {
          this.isAllSelected = false
        } else if (currentSelectedAfterPagination.length === this.document.specifications.length) {
          this.isAllSelected = true
        } else {
          this.isAllSelected = false
        }
      }
    },

    mounted() {},

    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      async remove_items(items) {
        await this.$apollo.mutate({
          mutation: require('../../gql/RemoveManufactureSpecificatioon.graphql'),
          variables: {
            input: {
              document_head: this.document?.id,
              ids: items
            }
          }
        })
        this.$apollo.queries.ManufactureHead.refresh()
        this.selected = []
      },

      changeSelectAll() {
        this.isAllSelected = !this.isAllSelected
        if (!this.isAllSelected) {
          this.document.specifications.forEach(
            (obj) => (this.selected = this.selected.filter((item) => obj.id !== item))
          )
          return
        }

        let arrayCurrenSelected = []
        this.document.specifications.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.document.specifications.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.document.specifications.length) this.isAllSelected = false
        if (currentSeleted.length === this.document.specifications.length) this.isAllSelected = true
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      openCompound(item) {
        this.currentCompound = item.current_compound
        this.$bvModal.show('compound-modal')
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }
        return 0
      },
      setDelta(val, item) {
        item.setDelta(val)
        document.getElementById(item.id + 'price')?.click()
        this.updateSpec(item)
      },

      async onChange() {
        await this.$apollo.mutate({
          mutation: require('../../gql/UpdateManufactureHead.graphql'),
          variables: {
            input: this.document.input
          }
        })
      },

      setPrice(val, item) {
        item.setPrice(val)
        document.getElementById(item.id + 'sum')?.click()
        this.updateSpec(item)
      },

      setSum(val, item) {
        item.setSum(val)
        document.getElementById(item.id + 'markup')?.click()
        this.updateSpec(item)
      },

      setMarkup(val, item) {
        item.setMarkup(val)
        document.getElementById(item.id + 'retailPrice')?.click()
        this.updateSpec(item)
      },

      setRetailPrice(val, item) {
        item.setRetailPrice(val)
        setTimeout(() => document.getElementById('productSearchInput').focus(), 700)
        this.updateSpec(item)
      },
      async updateSpec(spec) {
        await this.$apollo.mutate({
          mutation: require(`../../gql/UpdateManufactureSpec.graphql`),
          variables: {
            input: { ...spec.input, document_head: this.document.id }
          }
        })
      },
      async select_product(item) {
        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0
        const new_spec = new ManufactureSpecificationModel({
          ...item.specification,
          delta: item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount,
          product: new ProductModel(item),
          document_head: this.document
        })

        new_spec.setDelta(delta)
        const cost_price =
          new_spec.product.current_compound?.raw_material?.reduce((sum, el) => {
            return sum + (el.product?.purchasePrice || 0) * el.gross
          }, 0) / new_spec.product.current_compound?.value
        if (new_spec.product.purchasePrice || cost_price) {
          new_spec.setPrice(cost_price || new_spec.product.purchasePrice || 0)
          if (new_spec.product.markup) new_spec.setMarkup(+new_spec.product.markup)
          else if (!new_spec.product.markup && new_spec.product.retailPrice) {
            new_spec.setRetailPrice(new_spec.product.retailPrice)
          }
        }

        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/CreateManufactureSpec.graphql'),
          variables: {
            input: {
              document_head: new_spec.document_head.id,
              productChange: [new_spec.input]
            }
          }
        })

        await this.$apollo.queries.ManufactureHead.refresh()
        setTimeout(() => document.getElementById(data.CreateManufactureSpecification[0].id + 'delta')?.click(), 300)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      async update_status(status) {
        try {
          this.loading = true
          await this.$apollo.mutate({
            mutation: require('../../gql/ManufactureHeadChangeStatus.graphql'),
            variables: {
              input: {
                fixed: status,
                id: this.document.id,
                branch: this.document.branch.id
              }
            }
          })
          this.$apollo.queries.ManufactureHead.refresh()
        } catch (e) {
          this.loading = false
        } finally {
          this.loading = false
        }
        this.loading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #resizeable-table {
      background-color: white !important;
    }

    .p-0 {
      display: flex !important;
      flex-direction: column !important;
      flex: 1 !important;
    }
    .b-search {
      &-result {
        height: 400px;
      }
    }

    .table-docs.b-table-sticky-header {
      max-height: none;
    }
  }

  .b-search {
    &__wrapper {
      & > div {
        position: absolute;
      }
    }

    &-result {
      .item {
        &:hover {
          background: #efefef;
        }
      }
    }
  }

  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }

  .equipment-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .calc-icon:hover {
    cursor: pointer;
    background: #e2e2e2;
  }

  .calc-icon {
    padding: 6px;
    border-radius: 4px;
  }
</style>
