var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_10967_122154)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.3807 13.4872H13.4872M13.4872 13.4872H16.5938M13.4872 13.4872V10.3807M13.4872 13.4872V16.5938M3.47727 7.61932H5.5483C6.09756 7.61932 6.62434 7.40112 7.01273 7.01273C7.40112 6.62434 7.61932 6.09756 7.61932 5.5483V3.47727C7.61932 2.928 7.40112 2.40123 7.01273 2.01284C6.62434 1.62445 6.09756 1.40625 5.5483 1.40625H3.47727C2.928 1.40625 2.40123 1.62445 2.01284 2.01284C1.62445 2.40123 1.40625 2.928 1.40625 3.47727V5.5483C1.40625 6.09756 1.62445 6.62434 2.01284 7.01273C2.40123 7.40112 2.928 7.61932 3.47727 7.61932ZM3.47727 16.5938H5.5483C6.09756 16.5938 6.62434 16.3756 7.01273 15.9872C7.40112 15.5988 7.61932 15.072 7.61932 14.5227V12.4517C7.61932 11.9024 7.40112 11.3757 7.01273 10.9873C6.62434 10.5989 6.09756 10.3807 5.5483 10.3807H3.47727C2.928 10.3807 2.40123 10.5989 2.01284 10.9873C1.62445 11.3757 1.40625 11.9024 1.40625 12.4517V14.5227C1.40625 15.072 1.62445 15.5988 2.01284 15.9872C2.40123 16.3756 2.928 16.5938 3.47727 16.5938ZM12.4517 7.61932H14.5227C15.072 7.61932 15.5988 7.40112 15.9872 7.01273C16.3756 6.62434 16.5938 6.09756 16.5938 5.5483V3.47727C16.5938 2.928 16.3756 2.40123 15.9872 2.01284C15.5988 1.62445 15.072 1.40625 14.5227 1.40625H12.4517C11.9024 1.40625 11.3757 1.62445 10.9873 2.01284C10.5989 2.40123 10.3807 2.928 10.3807 3.47727V5.5483C10.3807 6.09756 10.5989 6.62434 10.9873 7.01273C11.3757 7.40112 11.9024 7.61932 12.4517 7.61932Z",
      "stroke": _vm.fill,
      "stroke-width": "1.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_10967_122154"
    }
  }, [_c('rect', {
    attrs: {
      "width": "18",
      "height": "18",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }