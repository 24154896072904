var render = function render(){
  var _vm$document;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('e-button', {
    staticClass: "btn-filters cursor",
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.changeVisibleParams
    }
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    class: {
      rotate: _vm.visibleParams
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons d-flex"
  }, [!_vm.document.fixed ? _c('e-button', {
    staticClass: "cursor",
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить черновик ")]) : _vm._e(), _c('e-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "size": "m",
      "loading": _vm.loading || _vm.is_request_update,
      "disabled": _vm.loading || _vm.is_request_update,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updateDocument(_vm.document.fixed);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.document.fixed ? 'Редактировать' : 'Провести') + " ")]), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn btn-white btn-more d-flex justify-content-center"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.printUpd
    }
  }, [_vm._v("Печать")])], 1)], 1)]), _c('e-collapse', {
    attrs: {
      "id": "collapse-filters",
      "show-button": false
    },
    model: {
      value: _vm.visibleParams,
      callback: function ($$v) {
        _vm.visibleParams = $$v;
      },
      expression: "visibleParams"
    }
  }, [_c('filters-equipment', {
    ref: "header_filters_outgoing",
    attrs: {
      "fixed": (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : _vm$document.fixed,
      "filter_params": _vm.document,
      "is_supplier_error": _vm.isSupplierError
    },
    on: {
      "change": _vm.change
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }